/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import axios from "axios";
import "./inicio.scss";
import { Row, Col, Button, Card } from "react-bootstrap";
import { Fetch, catchError, FetchApi } from "../../../utils/fetch";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import { Link } from "react-router-dom";
import DataGridForm from "../../../components/common/DataGridForm";
import MonthPickerInput from "react-month-picker-input";
require("react-month-picker-input/dist/react-month-picker-input.css");

function Inicio() {
  const [state, setState] = React.useState({
    monthYear: "",
    idUserType: 0,
    listado: [],
    usuariosRegistrados: "0 de 0",
    registros:{}
  });
  const dispatch = useDispatch();
  const isLoadingAction = (data) => dispatch(loadingAction(data));

  function Genero(data) {
    return (
      <>
        <span>{data.sex === "H" ? "Hombre" : "Mujer"}</span>
      </>
    );
  }

  React.useEffect(() => {
    onDatosInciales();
  }, []);

  const onDatosInciales = async () => {
    try {
      dispatch(loadingAction(true));
      const { data } = await FetchApi(
        `customers/Api/v1/User/GetTotalCustomersState`,
        "post",
        state
      );
      setState({
        ...state,
         registros:data?.data,
      });
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onBuscar = async () => {
    try {
      dispatch(loadingAction(true));
      const { data } = await FetchApi(
        `customers/Api/v1/User/GetCustomersByDate`,
        "post",
        state
      );
      setState({ ...state, listado: data?.data?.CustomerGroup });
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onChange = async (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  function Accion(data) {
    return (
      <>
        <Link
          to={"/modificar-usuario/" + data.idCustomer}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>
      </>
    );
  }
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-teal">
                <span>{(state?.registros?.validados/state?.registros?.registrados*100).toFixed(2)}%</span>
              </div>
              <div className="col-xs-8 padding-15 text-right">
                <h2 className="mv-0">{`${state?.registros?.validados} de ${state?.registros?.registrados}`}</h2>
                <div className="margin-b-0 text-muted">Usuarios validados</div>
              </div>
            </Row>
          </div>
        </Col>

        <Col>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-indigo">
                <span>{(state?.registros?.sinvalidar/state?.registros?.registrados*100).toFixed(2)}%</span>
              </div>
              <div className="col-xs-8 padding-15 text-right">
              <h2 className="mv-0">{`${state?.registros?.sinvalidar} de ${state?.registros?.registrados}`}</h2>
                <div className="margin-b-0 text-muted">Usuarios sin validar</div>
              </div>
            </Row>
          </div>
        </Col>

        <Col>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-primary">
                <span>100%</span>
              </div>
              <div className="col-xs-8 padding-15 text-right">
                <h2 className="mv-0">{state?.registros?.registrados}</h2>
                <div className="margin-b-0 text-muted">Usuarios registrados</div>
              </div>
            </Row>
          </div>
        </Col>

        
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Logs de usuarios registrados</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="3">
                  <label>Mes</label>
                  <MonthPickerInput
                    lang="es"
                    year={new Date().getFullYear()}
                    month={new Date().getMonth()}
                    onChange={function (
                      maskedValue,
                      selectedYear,
                      selectedMonth
                    ) {
                      setState({
                        ...state,
                        monthYear:
                          selectedYear +
                          "-" +
                          (selectedMonth + 1).toString().padStart(2, "00"),
                      });
                    }}
                  />
                </Col>
                <Col md="2">
                  <label>Tipo usuario</label>
                  <select
                    class="form-control"
                    onChange={onChange}
                    value={state.idUserType}
                    name="idUserType"
                  >
                    <option selected="selected" value="">
                      Seleccione
                    </option>
                    <option value="1">REGISTRADOS</option>
                    <option value="2">VALIDADOS</option>
                    <option value="3">SIN VALIDAR</option>
                  </select>
                </Col>
                {/* 
                                <Col md="2">
                                    <label>Cant. registros</label>
                                    <select class="form-control">
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                    </select>
                                </Col> */}

                <Col md="2" className="mt-31">
                  <label>&nbsp;</label>
                  <Button onClick={onBuscar}>
                    <i className="fa fa-search mr-2"></i>Buscar
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={state?.listado}
                  keyExpr="idCustomer"
                  columns={[
                    { caption: "Apellido", dataField: "lastName" },
                    { caption: "Nombre", dataField: "firstName" },
                    { caption: "Email", dataField: "email" },
                    { caption: "Fecha de nacimiento", dataField: "birthday" },
                    { caption: "DNI", dataField: "dni" },
                    {
                      caption: "Género",
                      alignment: "left",
                      render: "Genero",
                      cellRender: { Genero },
                    },
                    { caption: "Registro", dataField: "registrationDate" },
                    { caption: "Validación", dataField: "state" },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Inicio;
