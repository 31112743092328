import { Modal } from "react-bootstrap";
import DataGridForm from "../../../../components/common/DataGridForm";

const ModalData = ({ isShow, member, handleClose }) => {
  return (
    <Modal show={isShow} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Resultado de Búsqueda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataGridForm
          exportEnabled={true}
          dataSource={member} // Aquí pasamos los datos obtenidos
          keyExpr="idLog"
          columns={[
            { caption: "Fecha", dataField: "date", width: 150 },
            { caption: "Email", dataField: "email", width: 220 },
            { caption: "Dni", dataField: "dni", width: 140 },
            { caption: "Credencial", dataField: "credencial", width: 150 },
            { caption: "Descripción", dataField: "description" },
          ]}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalData;
