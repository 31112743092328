import { useState } from 'react';
import DataGrid, {
  LoadPanel,
  Column,
  FilterRow,
  Paging,
  SearchPanel,
  Export,
  RowDragging,
  Button
} from 'devextreme-react/data-grid';

export const DataGridForm2 = (
  {
    dataSource,
    allowColumnReordering = true,
    showBorders = true,
    rowAlternationEnabled = true,
    showRowLines = true,
    hoverStateEnabled = true,
    exportEnabled = false,
    exporting = 'archivo',
    focusedRowEnabled = true,
    filterRow = true,
    searchPanel = false,
    defaultPageSize = 10,
    keyExpr,
    columns = [],
    onReorder,
    allowReordering = false,
    columnHidingEnabled = false,
    allowColumnResizing = true,
    remoteOperations = false,
    pageSize = 10,
    filterValues, // Recibe los valores del formulario
    onDataChange,
    onFilterChange,
    onRowClick = () => null,
    onCellClick, // Prop opcional desde el padre
    ...props // Captura cualquier otra prop
  }) => {

  const [selectedValue, setSelectedValue] = useState(null);

  const handleFilterChange = async (value) => {
    // Llama a la función onFilterChange pasada desde el componente principal
    onFilterChange({
      ...filterValues, // Mantén los filtros anteriores
      columnNew: value, // Agrega el nuevo filtro aplicado
      column: selectedValue
    });
  };

  return (
    <DataGrid
      dataSource={dataSource}
      allowColumnReordering={allowColumnReordering}
      showBorders={showBorders}
      rowAlternationEnabled={rowAlternationEnabled}
      showRowLines={showRowLines}
      hoverStateEnabled={hoverStateEnabled}
      focusedRowEnabled={focusedRowEnabled}
      columnHidingEnabled={false}
      allowColumnResizing={false}
      width="100%"
      columnAutoWidth={true}
      columnResizingMode="widget"
      wordWrapEnabled={false}
      keyExpr={keyExpr}
      onCellClick={(cell) => {
        setSelectedValue(cell.column.dataField); // ✅ Actualización del estado
        console.log('Valor:', cell.column.dataField);
        onCellClick?.(cell); // Propaga el evento al padre
      }}
      remoteOperations={remoteOperations}
      onOptionChanged={(e) => {
        if (e.value != null && e.value.length > 5) {
          console.log("Filtro aplicado en:", e.component.columnOption(e.name, "dataField"), "Valor:", e.value);
          handleFilterChange(e.value);
        }
      }}
      columns={columns}
      onRowClick={(e) => {
        if (onRowClick) {
          onRowClick(e.data); // Pasar los datos de la fila seleccionada
        }
      }}
      {...props}
    >
      <RowDragging
        allowReordering={allowReordering}
        onReorder={onReorder}
      />
      <LoadPanel enabled />
      <FilterRow visible={filterRow} />
      <SearchPanel visible={searchPanel} highlightCaseSensitive={true} />
      <Paging defaultPageSize={defaultPageSize} pageSize={pageSize} />
      <Export enabled={exportEnabled} />

      {columns.map((c, i) => {

        if (c.view != undefined)
          return <Column type="buttons" width={110} key={i.toString()}>
            <Button hint="Clone" cssClass={"btn btn-primary"} onClick={(e) => c.onTap(e)} children={<span>VER</span>} />
          </Column>

        if (c.viewPat != undefined)
          return <Column type="buttons" width={200} key={i.toString()}>
            <Button hint="Clone" cssClass={"btn btn-primary"} onClick={(e) => c.onTap(e)} children={<span>Ver Datos Paciente</span>} />
          </Column>

        if (c.edit !== undefined)
          return <Column type="buttons" width={110} key={i.toString()}>
            <Button hint="Clone" cssClass={"btn btn-primary"} onClick={(e) => c.onTap(e)} children={<span>EDITAR</span>} />
          </Column>

        if (c.dataField !== undefined)
          return <Column key={i.toString()}
            dataField={c.dataField}
            caption={c.caption}
            alignment={c.alignment}
            width={c.caption == "Id" ? 50 : c.width}
            allowSorting={c.allowSorting}
            hidingPriority={c.hidingPriority}
          />

        if (c.dataField === undefined)
          return <Column key={i.toString()}
            caption={c.caption}
            alignment={c.alignment}
            width={c.caption == "Id" ? 50 : c.width}
            allowSorting={c.allowSorting}
            cellRender={(item) => c.cellRender[c.render](item.data)}
            hidingPriority={c.hidingPriority}
          />
      })}

    </DataGrid>
  )
}

export default DataGridForm2